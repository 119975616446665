import Dropdown from '@theme/components/utils/Dropdown'

export default {
  components: {
    Dropdown,
  },
  props: {
    id: String,
    dropdown: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.DisplayBothPricesSwitcher.dropdown
      },
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      values: {
        on: {
          value: true,
          label: this.$t('DisplayBothPricesSwitcher.on'),
        },
        off: {
          value: false,
          label: this.$t('DisplayBothPricesSwitcher.off'),
        },
      },
    }
  },
  computed: {
    dropdownId() {
      return this.id || 'DisplayBothPricesSwitcher'
    },
    label() {
      return this.$store.state.currency.displayBothPrices ? this.values.on.label : this.values.off.label
    },
  },
  methods: {
    dropdownMethod() {},
    selectDisplayPricesSetting(setting) {
      this.$store.dispatch('currency/CHANGE_DISPLAY_BOTH_PRICES', setting)
    },
  },
  async mounted() {
    const cookie = this.$cookies.get('X-Inspishop-DisplayBothPrices')
    if (cookie !== undefined) {
      await this.$store.dispatch('currency/CHANGE_DISPLAY_BOTH_PRICES', cookie)
    }
  },
}
